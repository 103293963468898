<template>
  <sdModal
    type="primary"
    :width="displayFlow == 'pay-for-plan' ? 500 : 1000"
    :visible="showPlans && displayFlow == 'select-plan'"
    :closable="closable"
    :onCancel="hidePlansModal"
  >
    <PlanModalWrapper>
      <div class="plan_modal_headers">
        <h1 class="plan_modal_title">Choose your plan to get started</h1>
        <!-- <div class="plan_modal_note">If you need more info, please check <a>Pricing Guidelines</a>.</div> -->
      </div>
      <div class="spin" v-if="fetchingPlans">
        <a-spin />
      </div>
      <div v-else>
        <a-row :gutter="15" style="margin-top: 30px">
          <a-col :lg="12" :sm="24">
            <sdCards
              headless
              :class="[
                'plan-card',
                item.id == user.active_plan && 'plan_disable',
              ]"
              v-for="(item, index) of plans"
              :key="item.encrypt_id"
              @click="onPlanSelect(item.encrypt_id, index)"
              :bodyStyle="
                selectedPlanId == item.encrypt_id ? selectedPlanBox : planBox
              "
            >
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <a-radio :checked="selectedPlanId == item.encrypt_id" />
                  <div :class="[item.id == user.active_plan && 'custom-badge']">
                    <h2
                      :class="[
                        '',
                        selectedPlanId == item.encrypt_id &&
                          'selected-plan-name',
                      ]"
                    >
                      {{ item.name }}
                      <span
                        class="badge badge-light-success ms-2 fs-7"
                        v-if="item.id == user.active_plan"
                        >Current Plan</span
                      >
                    </h2>
                    <span
                      :class="[
                        'package-user-type',
                        selectedPlanId == item.encrypt_id &&
                          'selected-package-user-type',
                      ]"
                      > {{ item.plan_description }}</span
                    >
                  </div>
                </div>
                <sdHeading v-if="item.price > 0" class="price-amount" as="h3">
                  <sub
                    :class="[
                      'pricing-validity',
                      selectedPlanId == item.encrypt_id &&
                        'selected-pricing-validity',
                    ]"
                    >$</sub
                  >
                  <sub
                    :class="[
                      'currency',
                      selectedPlanId == item.encrypt_id && 'selected-currency',
                    ]"
                  >
                    {{ item.price }}</sub
                  >
                  <sub
                    :class="[
                      'pricing-validity',
                      selectedPlanId == item.encrypt_id &&
                        'selected-pricing-validity',
                    ]"
                  >
                    / month</sub
                  >
                </sdHeading>
                <sdHeading
                  v-else
                  :class="[
                    'pricing-title',
                    selectedPlanId == item.encrypt_id &&
                      'selected-pricing-title',
                  ]"
                  as="h3"
                >
                  Free
                </sdHeading>
              </div>
            </sdCards>
          </a-col>
          <a-col :lg="12" :sm="24">
            <sdCards
              headless
              style="height: 95%"
              :bodyStyle="{ background: '#F4F5F7', height: '100%' }"
            >
              <div class="plan-description-title">
                <h2>
                  What’s in
                  {{ plans[planIndex].name.replace("plan", "") }} Plan?
                </h2>
              </div>
              <ListGroup>
                <div
                  class="plan-description-wraper"
                  v-for="(desc, ind) of plans[planIndex].description"
                  :key="ind"
                >
                  <span class="plan-description-text">{{ desc }}</span>
                  <sdFeatherIcons type="check-circle" size="20" />
                </div>
              </ListGroup>
            </sdCards>
          </a-col>
        </a-row>
        <div class="purchase-buttons">
          <sdButton
            v-if="closable"
            @click="hidePlansModal"
            size="small"
            type="light"
            style="margin-right: 10px"
          >
            Cancel
          </sdButton>
          <sdButton @click="onUpgradePress" size="small" type="primary">
            Upgrade Plan
          </sdButton>
        </div>
      </div>
    </PlanModalWrapper>
  </sdModal>
  <sdModal
    type="primary"
    :width="500"
    :visible="showPlans && displayFlow == 'pay-for-plan'"
    :closable="closable"
    :onCancel="hidePlansModal"
  >
    <PaymentCardWrapper>
      <div class="atbd-form-checkout">
        <a-row justify="center">
          <a-col :xs="24">
            <div class="payment-method-form theme-light">
              <div class="shipping-selection">
                <div class="shipping-selection__card">
                  <div class="supported-card d-flex">
                    <span>Credit/Debit Card</span>
                    <div class="supported-card_logos">
                      <img
                        :style="{ width: '50px' }"
                        :src="require('@/assets/img/cards-logo/ms.png')"
                        alt=""
                      />
                      <img
                        :style="{ width: '50px' }"
                        :src="
                          require('@/assets/img/cards-logo/american-express.png')
                        "
                        alt=""
                      />
                      <img
                        :style="{ width: '50px' }"
                        :src="require('@/assets/img/cards-logo/visa.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <sdCards headless :style="{ marginBottom: 0 }">
                    <a-form
                      :hideRequiredMark="true"
                      ref="formRef"
                      :rules="cardRules"
                      :model="cardStates"
                      name="info"
                    >
                      <a-form-item name="card_number" label="Card Number">
                        <a-input
                          v-on:change="handleCardNumberInput"
                          v-model:value="cardStates.card_number"
                          placeholder="6547-8702-6987-2527"
                        />
                      </a-form-item>
                      <a-form-item name="name" label="Name on Card">
                        <a-input
                          v-model:value="cardStates.name"
                          placeholder="Full name"
                        />
                      </a-form-item>
                      <a-row :gutter="25">
                        <a-col :lg="12" :xs="24">
                          <a-form-item name="exp_month" label="Expiration Date">
                            <a-input
                              v-on:change="handleMonth"
                              v-model:value="cardStates.exp_month"
                              placeholder="MM/YY"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col :lg="12" :xs="24">
                          <a-form-item name="card_cvv" label="CVV">
                            <a-input
                              v-on:change="handleCVV"
                              v-model:value="cardStates.card_cvv"
                              placeholder="XXX"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    <div class="d-flex justify-content-around">
                      <sdButton
                        v-if="closable"
                        @click="hidePlansModal()"
                        shape="circle"
                        type="light"
                        >Cancel</sdButton
                      >
                      <sdButton
                        @click="activatePlan()"
                        shape="circle"
                        type="primary"
                      >
                        <a-spin v-if="planActivating" :indicator="indicator" />
                        Pay Now
                      </sdButton>
                    </div>
                  </sdCards>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </PaymentCardWrapper>
  </sdModal>
</template>

<script>
import { h } from "vue";
import VueTypes from "vue-types";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { PlanModalWrapper, ListGroup, PaymentCardWrapper } from "./style";

import { API } from "@/services/api";
import Notification from "@/services/Notification";

import { useStore } from "vuex";

export default {
  name: "PlanModal",
  components: {
    ListGroup,
    PlanModalWrapper,
    PaymentCardWrapper,
  },
  computed: {
    userId: function () {
      return this.$store.getters.authUser.encrypt_id;
    },
    formRef: function () {
      return this.$refs.formRef;
    },
    user: function () {
      return this.$store.getters.authUser;
    },
  },
  props: {
    closable: VueTypes.bool.def(true),
  },
  emits: ["onPlanPurchaseComplete"],
  data() {
    return {
      indicator: h(LoadingOutlined, {
        style: { fontSize: "24px", color: "white", marginRight: "10px" },
        spin: true,
      }),
      displayFlow: "select-plan",
      showPlans: false,
      fetchingPlans: true,
      planActivating: false,
      plans: [],
      selectedPlanId: null,
      planIndex: 0,
      planBox: {
        padding: "25px 15px !important",
        border: "1px dashed #e4e6ef",
        borderRadius: "5px",
      },
      selectedPlanBox: {
        padding: "25px 15px !important",
        background: "#C72C51",
        borderRadius: "5px",
      },
      cardStates: {
        card_number: "",
        name: "",
        exp_month: "",
        card_cvv: "",
      },
      cardRules: {
        card_number: [
          {
            required: true,
            trigger: "blur",
            message: "Please input card number",
          },
          {
            min: 12,
            trigger: "blur",
            message: "Minimum 12 number required",
          },
          {
            type: "number",
            trigger: "blur",
            transform: (val) => Number(val.replace(new RegExp(" ", "g"), "")),
            message: "Please input valid card number",
          },
        ],
        name: [
          {
            required: true,
            trigger: "blur",
            message: "Please input name on card",
          },
        ],
        exp_month: [
          {
            trigger: "blur",
            validator: this.validateMonth,
          },
        ],
        card_cvv: [
          {
            trigger: "change",
            validator: this.validateCVV,
          },
        ],
      },
    };
  },
  methods: {
    showPlansModal() {
      this.showPlans = true;
    },
    hidePlansModal() {
      this.showPlans = false;
      this.displayFlow = "select-plan";
      if (this.formRef) this.formRef.clearValidate();
      this.cardStates = {
        card_number: "",
        name: "",
        exp_month: "",
        card_cvv: "",
      };
    },
    onPlanSelect(id, ind) {
      this.selectedPlanId = id;
      this.planIndex = ind;
    },
    onUpgradePress() {
      this.showPlans = false;
      setTimeout(() => {
        this.displayFlow = "pay-for-plan";
        this.showPlans = true;
      }, 500);
    },
    async getPlans() {
      try {
        const params = {
          page: 1,
          limit: 20,
        };
        const res = await API.getPackage({ params });
        const { status, message, data } = res.data;
        if (!status && message) {
          Notification.show(message, { type: "error", title: "Error" });
        }
        if (status) {
          const plans = data?.package ?? [];
          this.plans = plans;
          this.selectedPlanId = plans[this.planIndex].encrypt_id;
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.fetchingPlans = false;
      }
    },
    async activatePlan() {
      try {
        this.planActivating = true;
        await this.formRef.validate();
        const fData = new FormData();
        fData.append("user_id", this.userId);
        fData.append("plan_id", this.selectedPlanId);
        fData.append("card_number", this.cardStates.card_number);
        fData.append("name", this.cardStates.name);
        fData.append("exp_month", this.cardStates.exp_month);
        fData.append("cvv", this.cardStates.card_cvv);

        const res = await API.activatePlan(fData);
        const { message = "", status } = res.data;
        if (message) {
          Notification.show(message, {
            type: status ? "success" : "error",
            title: status ? "Success" : "Error",
          });
        }
        if (status) {
          await this.$store.dispatch("getProfile");
          this.$emit("onPlanPurchaseComplete");
        }
      } catch (error) {
        if (error?.message) {
          Notification.show(error.message, { type: "error", title: "Error" });
        }
      } finally {
        this.planActivating = false;
      }
    },
    handleCardNumberInput(e) {
      let v = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      var matches = v.match(/\d{4,16}/g);
      var match = (matches && matches[0]) || "";
      var parts = [];
      for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4));
      }
      let val = "";
      if (parts.length) {
        val = parts.join(" ");
      } else {
        val = e.target.value;
      }
      this.cardStates.card_number = val;
    },
    handleMonth(e) {
      let v = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      var matches = v.match(/\d{2,4}/g);
      var match = (matches && matches[0]) || "";

      var parts = [];
      for (let i = 0, len = match.length; i < len; i += 2) {
        parts.push(match.substring(i, i + 2));
      }

      let val = "";
      if (parts.length) {
        if (Number(parts[0]) > 12) {
          parts[0] = "12";
        }
        val = parts.join("/");
      } else {
        val = e.target.value;
      }
      this.cardStates.exp_month = val;
    },

    handleCVV(e) {
      let v = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
      if (v.length > 4) {
        v = v.substring(0, 4);
      }
      this.cardStates.card_cvv = v;
    },
    validateMonth(_rule, value) {
      if (value.length < 1) {
        return Promise.reject("Please input card expire date");
      } else {
        return Promise.resolve();
      }
    },
    validateYear(_rule, value) {
      if (value.length < 1) {
        return Promise.reject("Please input card expire year");
      } else if (value.length < 4) {
        return Promise.reject("Please input valid card expire year");
      } else {
        return Promise.resolve();
      }
    },
    validateCVV(_rule, value) {
      if (value.length < 1) {
        return Promise.reject("Please input card cvv number");
      } else if (value.length < 3 || value.length > 4) {
        return Promise.reject("Please input valid card cvv number");
      } else {
        return Promise.resolve();
      }
    },
  },
  created() {
    this.getPlans();
  },
};
</script>