import Styled from 'vue3-styled-components';

const WizardBlock = Styled.div`
  >.ant-card{
    .ant-card-body{
      padding: 50px 0 !important;
    }
    .steps-content{
      .ant-card-body{
        padding: 25px !important;
      }
    }
  }
`;

const PlanModalWrapper = Styled.div`
  .plan-card {
    .price-amount{
      font-size: 36px;
      margin-bottom: 10px;
      .currency{
        font-size: 3rem;
        font-weight: 600;
        top: 3px;
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 2px;
        color: ${({ theme }) => theme['dark-color']};
      }
      .selected-currency{
        color: ${({ theme }) => theme['white-color']};
      }
      .pricing-validity{
        font-size: 13px;
        font-weight: 400;
        bottom: 0;
        ${({ theme }) => (!theme.rtl ? 'left' : 'right')}: -2px;
        color: ${({ theme }) => theme['light-color']};
      }
      .selected-pricing-validity{
        color: ${({ theme }) => theme['white-color']};
      }
    }
    .pricing-title{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    .selected-pricing-title{
      color: ${({ theme }) => theme['white-color']};
    }
    .package-user-type{
      font-size: 13px;
      font-weight: 500;
      color: ${({ theme }) => theme['gray-color']};
    }
    .selected-package-user-type{
      color: ${({ theme }) => theme['white-color']};
    }
  }
  h2 {
    margin-bottom: 0;
  }
  .selected-plan-name {
    color: ${({ theme }) => theme['white-color']} !important;
  }
  .ant-card-body {
    .plan-description-title {
      h2 {
        margin-bottom: 1.25rem;
        font-weight: 600;
        color: ${({ theme }) => theme['dark-color']};
      }
    }
  }
  .purchase-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const ListGroup = Styled.div`
  margin: 28px 0 15px;
  .list-single{
    display: flex;
    align-items: center;
    &:not(:last-child){
      margin-bottom: 12px;
    }
    .icon{
      display: inline-block;
      margin: ${({ theme }) => (theme.rtl ? '0px 0 -4px 10px' : '0px 10px -4px 0')};
    }
  }
  .plan-description-wraper {
    display: flex;
    align-items: center;
    margin-bottom: 1.15rem;
    .plan-description-text {
      font-weight: 500;
      font-size: 0.95rem;
      flex-grow: 1;
      color: ${({ theme }) => theme['gray-color']};
    }
    i {
      svg {
        color: ${({ theme }) => theme['success-color']};
      }
    }
  }
`;

const PaymentCardWrapper = Styled.div`
  .atbd-form-checkout{
    margin-top: 20px;
    .ant-input-affix-wrapper-rtl{
      input[type="password"]{
        ${({ theme }) => (theme.rtl ? 'padding-left' : 'padding-right')}: 15px;
      }
    }
    .ant-form-item-label{
      label{
        font-size: 15px;
      }
    }
    input::placeholder{
      color: ${({ theme }) => theme['extra-light-color']};
    }
    .input-message{
      margin-top: -6px;
      display: inline-block;
      font-size: 13px;
      color: ${({ theme }) => theme['gray-solid']};
    }

    .shipping-selection{
      > .ant-card{
        .ant-card-body{
          border: 1px solid ${({ theme }) => theme['border-color-light']};
        }
    }
    .ant-card{
      .ant-card{
        margin-bottom: 0 !important;
      }
    }
    .ant-form-item-control{
      input{
        @media only screen and (max-width: 479px) {
          width: 100% !important;
          margin-bottom: 6px;
        }
      }
      .input-leftText{
        ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 10px;
        @media only screen and (max-width: 479px) {
          display: block;
          ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 0px;
        }
      }
    }
    .shipping-selection__card{
      .ant-card .ant-card{
        border: 0 none;
        box-shadow: 0 10px 30px ${({ theme }) => theme['light-color']}10;
      }
      .ant-radio-wrapper{
        .ant-radio{
          margin-top: 30px;
        }
        span + span{
          padding: 0;
        }
      }
      .cvv-wrap{
        input{
          max-width: 120px;
        }
        .input-leftText{
          color: ${({ theme }) => theme['color-info']};
        }
      }
    }
    .shipping-selection__paypal{
      margin-bottom: 20px;
      .ant-radio-wrapper{
        span + span{
          display: flex;
          justify-content: space-between;
          @media only screen and (max-width: 375px){
            img{
              display: none;
            }
          }
        }
      }
    }
    .supported-card{
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      @media only screen and (max-width: 479px) {
        flex-flow: column;
      }
      .supported-card_logos{
        @media only screen and (max-width: 479px) {
          margin-top: 12px;
        }
        img + img{
          ${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
        }
      }
    }
  }
`;

export {
  ListGroup,
  WizardBlock,
  PlanModalWrapper,
  PaymentCardWrapper,
};
